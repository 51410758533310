<template>
  <div>
    <div style="clear: both;height: 130px;">
      <div class="doc-header1">List of CAPA</div>
    </div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow-capa">
            <div class="card-body">
              <div>
              <span>Add and tag a CAPA in a document step for usage log, tracking and traceability by typing "@"</span>
              <base-button
                  size="sm"
                  type="default"
                  class="btn btn-info float-right custom-pad-bot"
                  @click="addNew()"
                >Add New</base-button>
              </div>

              <div class="table-responsive">
                <base-table
                  class="table align-items-center table-flush"
                  thead-classes="table-equ"
                  tbody-classes="list"
                  :data="tableData"
                >
                  <template slot="columns">
                    <th>Capa ID</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th>Created By</th>
                    <th>Audit Log</th>
                    <th>Track Inventory</th>
                    <th>Stock / Lifecycle</th>
                    <th>Remainder</th>
                  </template>

                  <template slot-scope="{row}">
                    <td v-on:click="handleRowClick(row)">
                      <div class="avatar-group">
                        <a href="#" class="capa-title"><span class="status" v-html="row.title"></span></a>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.description}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status"><p v-if="row.imagePath"><img style="width:50px; height:50px;" :src="row.imagePath" alt=""></p></span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.createdBy}} - {{formatDate(row.createdTime)}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <a href="#" @click="viewUsage(row)" class="status">View</a>
                      </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <input type="checkbox" :id="`track-${ row.capaID }`" name="track" value="track" @change="displayNumber(row)">
                      </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <span class="status" :id="row.capaID" 
                        style="display:none">{{ stock }}</span>
                      </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <span class="status" :id="row.title" 
                        style="display:none">{{ stock }} - </span>
                      </div>
                    </td>
                  </template>
                </base-table>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <base-pagination :per-page="5" :total="tableData.length"></base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAuditModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Auditlogs for capa - " + title }}
                  <button
                    type="button"
                    class="close"
                    @click="showAuditModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(documentTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Auditlogs for capa</span>
                  <div class="data-grid">
                      <ag-grid-vue
                        style="height: 200px"
                        class="ag-theme-balham"
                        :gridOptions="gridOptions"
                        :rowData="capaLogData"
                        rowSelection="multiple"
                        rowHeight="50"
                        pagination="true"
                        paginationPageSize="10"
                        floatingFilter="true" 
                      >
                      </ag-grid-vue>
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showAuditModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import { AgGridVue } from "ag-grid-vue";
import axios from "../../utils/axiosinstance";

Vue.use(VueClipboard);
export default {
  components: { AgGridVue },
  directives: {
    "b-tooltip": BTooltipDirective
  },
  data() {
    return {
      industryItemType: "",
      industryItemID: "",
      capaID: "",
      tableData: [],
      title: "",
      showAuditModal: false,
      gridOptions: null,
      capaLogData: null,
      stock: "100",
      logsData: [],
    };
  },
  beforeMount(){
      this.capaID=this.$route.query.capaid;
      this.industryItemType=this.$route.query.industryItemType;
      this.industryItemID=this.$route.query.industryItemID;
      this.gridOptions = {
      columnDefs: this.createColDefs(),
    };
},
  mounted() {
    this.loadDocument();
  },
  methods: {
    addNew(){
      let app = this;
      app.$router.push({
          name: "newCapa",  
          // params: { docid: "6e6e160f-e479-4148-a275-4eb0d8b69262" }
          params: {  }
        });
    },
    loadDocument()
    {
      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if( authCookieObj.startsWith("j:"))
      {
        authObj = JSON.parse(decodeURIComponent(this.getCookie("auth-token").substring(2).split(';')[0]));
      }
      else{
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(';')[0]));
      }
      if(authObj.departments && authObj.departments[0] ){
        var capaListURL;
      capaListURL = "/api/capa/organizations/" + authObj.orgs[0].orgID + "?industryItemType=capa";
      axios
        .get(capaListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
      else{
        capaListURL = "/api/capa/organizations/" + authObj.orgs[0].orgID + "?industryItemType=capa";
      axios
        .get(capaListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
    },
    displayNumber(item){
      console.log(item);
      var checkBox = event.target;
      var text = document.getElementById(item.capaID);
      var remainder = document.getElementById(item.title);
      if (checkBox.checked == true){
        text.style.display = "block";
        remainder.style.display = "block";
        this.showingLogs(item);
      } 
      else {
        text.style.display = "none";
        remainder.style.display = "none";
      }
    },
    showingLogs(item){
      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if( authCookieObj.startsWith("j:"))
      {
        authObj = JSON.parse(decodeURIComponent(this.getCookie("auth-token").substring(2).split(';')[0]));
      }
      else{
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(';')[0]));
      }
      var docListURL = "/api/organizations/" + authObj.orgs[0].orgID + "/usagelog?capaID=" + item.capaID;
      let app = this;
      axios
        .get(docListURL)
        .then(response => {
          console.log(response);
          app.logsData = response.data.items;
          var remainder = document.getElementById(item.title);
          let textAppended = false;
          if (!textAppended) { 
            remainder.textContent = 100 + " - " + app.logsData.length;
            textAppended = true;
          }
        })
        .catch(reason => {
          console.log(reason);
          app.logsData = [];
        });
    },
    viewUsage(item){
      const title = item.title;
      const capaID = item.capaID;
      window.location.href = `/viewUsageCapa?title=${title}&capaID=${capaID}`;
    },
    viewAuditLogPopup(item){
      this.showAuditModal = true;
      this.title = item.title;
      this.loadAuditLog(item);
    },
    loadAuditLog(item){
      var auditLogListURL = "/api/auditLogs?name=capa&id="+item.capaID;
      axios
        .get(auditLogListURL)
        .then(response => {
          console.log(response);
          this.capaLogData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.capaLogData = [];
        });
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    handleRowClick(item) {
      window.location.href ="/artifact/doclist?industryItemType=capa" +"&docStatusType=draft&templateType=document&industryItemID=" + item.capaID;
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    createColDefs() {
      // var that = this;
      var colDefs = [
        {
          headerName: "OPERATION",
          field: "operation",
          sortable: true,
          filter: true,
        },
        {
          headerName: "DATE",
          field: "date",
          sortable: true,
          filter: true,
        },
        {
          headerName: "BODY",
          field: 'updatedBy',
          sortable: false,
          filter: false,
          cellRenderer: function (params) {
            return JSON.stringify(params.data);
          }
        },
      ];
      return colDefs;
    },
  }
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-equ{
  background-color: lightgreen;
  font-size: 14px;
}
.table-rea{
  background-color: lightpink;
  font-size: 14px;
}
.doc-header1 {
  font-weight: 700;
  // position: relative;
  text-align: center;
  left: 110px;
  background-color: #ffff;
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px);
}
a.capa-title {
  color: #000;
}
a.capa-title:hover {
  text-decoration: underline !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.share-modal {
  max-width: 50%;
}

.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  display: flex;
  align-items: center;
  overflow: auto;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
