<template>
  <div>
    <div style="clear: both;height: 130px;">
      <div class="user-header">List of Users</div>
    </div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-body">
              <div>
              <span>Add and tag a Users in a document step for usage log, tracking and traceability by typing "@"</span>
              <base-button
                  size="sm"
                  type="default"
                  class="btn btn-info float-right custom-pad-bot"
                  @click="addNew()"
                >Add New</base-button>
              </div>
              <div class="user-grid">
                <ag-grid-vue
                  style="height: 500px; width: 100%"
                  class="ag-theme-balham"
                  :gridOptions="gridusers"
                  :rowData="tableData"
                  rowSelection="multiple"
                  rowHeight="50"
                  pagination="true"
                  paginationPageSize="10"
                  floatingFilter="true"
                >
                </ag-grid-vue>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";

Vue.use(VueClipboard);
export default {
  components: { AgGridVue },
  props: ["type" ],
  directives: {
    "b-tooltip": BTooltipDirective
  },
  data() {
    return {
      userID: "",
      title: "",
      industryItemType: "",
      industryItemID: "",
      tableData: null,
      showAuditModal: false,
      gridOptions: null,
      gridusers: null,
      userLogData: null,
    };
  },
  beforeMount(){
      this.userID=this.$route.query.userid;
      this.industryItemType=this.$route.query.industryItemType;
      this.industryItemID=this.$route.query.industryItemID;
      this.gridOptions = {
      columnDefs: this.createColDefs(),
    };
    this.gridusers = {
      columnDefs: this.createuserDefs(),
    };
},
  mounted() {
    this.loadDocument();
  },
  methods: {
    addNew(){
      let app = this;
      app.$router.push({
          name: "newUsers",  
        });
    },
    loadDocument()
    {
      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if( authCookieObj.startsWith("j:"))
      {
        authObj = JSON.parse(decodeURIComponent(this.getCookie("auth-token").substring(2).split(';')[0]));
      }
      else{
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(';')[0]));
      }
      var userListURL = "/api/user/organizations/" + authObj.orgs[0].orgID;
      axios
        .get(userListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
    },
    viewUsage(item){
      let app = this;
      app.$router.push({
        name: "viewUsageUsers",
        params: { userID: item.userID, title: item.title },
      });
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    handleRowClick(item) {
      window.location.href ="/artifact/doclist?industryItemType=user" +"&docStatusType=draft&templateType=document&industryItemID=" + item.userID;
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    createColDefs() {
      // var that = this;
      var colDefs = [
        {
          headerName: "OPERATION",
          field: "operation",
          sortable: true,
          filter: true,
        },
        {
          headerName: "DATE",
          field: "date",
          sortable: true,
          filter: true,
        },
        {
          headerName: "BODY",
          field: 'updatedBy',
          sortable: false,
          filter: false,
          cellRenderer: function (params) {
            return JSON.stringify(params.data);
          }
        },
      ];
      return colDefs;
    },
    createuserDefs() {
      var that = this;
      var colDefs = [
        {
          headerName: "USER NAME",
          field: "userName",
          sortable: true,
          width: 175, 
          cellStyle: { "white-space": "normal", 'line-height': 1.5 }, 
          autoHeight: true,
          filter: true,
          cellRenderer: function (params) {
            console.log(params);
            
            return (
              '<a href="#" class="title" title="' +
              params.value +
              '">' +
              params.value +
              "</a>"
            );
          },
          onCellClicked: function (params) {
            console.log(params);
            console.log(params.data);
            return that.handleRowClick(params.data);
          },
        },
        {
          headerName: "EMAIL",
          field: "email",
          sortable: true,
          width: 175, 
          cellStyle: { "white-space": "normal", 'line-height': 1.5 }, 
          autoHeight: true,
          filter: true,
        },
        {
          headerName: "CREATED BY",
          field: "createdBy",
          sortable: true,
          filter: true,
        },
        {
          headerName: "ROLE",
          field: "role",
          sortable: true,
          filter: true,
        },
        {
          headerName: "CREATED DATE",
          field: "createdTime",
          sortable: true,
          filter: true,
        },
        {
          headerName: "NAME",
          field: "name",
          sortable: true,
          filter: true,
        },
        {
          headerName: "ORGANIZATION",
          field: "orgID",
          sortable: true,
          filter: true,
        },
      ];
      
      return colDefs;
    },
    created() {
    
  },
  }
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-equ{
  background-color: lightgreen;
  font-size: 14px;
}
.table-rea{
  background-color: lightpink;
  font-size: 14px;
}
.user-header {
  font-weight: 700;
  // position: relative;
  margin-left: 40px;
  // background-color: #ffff;
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px);
}
a.user-title {
  color: #000;
}
a.user-title:hover {
  text-decoration: underline !important;
}
a.title {
  color: #000;
}
a.title:hover {
  text-decoration: underline !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.share-modal {
  max-width: 50%;
}

.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  align-items: center;
}
.ag-theme-balham .ag-cell {
    line-height: 15px !important; 
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid transparent;
    padding-left: 11px;
    padding-right: 11px;
    display: -webkit-inline-box !important;

}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.user-grid{
  margin-top: 35px;
}
// .ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
//     text-overflow: ellipsis !important;
//     overflow: hidden;
// }
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
